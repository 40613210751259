import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import SectionHero from '../components/section-hero'
import { useBusinessManImage } from '../hooks/businessman-hook'
import { CtaForm } from '../components/cta-form'
import { ProductSection } from '../components/section'

const BlogPostTemplate = ({ data }) => {
	const post = data.markdownRemark

	return (
		<Layout>
			<Seo
				title={post.frontmatter.title}
				description={post.frontmatter.description || post.excerpt}
			/>
			<SectionHero
				image={useBusinessManImage()}
				title1="Options & Trading Blog"
				title2="Rapid Fire Options"
				body="Rapid Fire Options is a screener based service based on unusual activities in certain options spanning individual stocks, SPACs, ETFs, Futures, etc. You have the ability to pick which one you want to get into and leave others."
				button="Join Today!"
				bgPosition={{ backgroundPosition: '25% 25%' }}
			/>
			<div className="blog-post container-xl flex items-center justify-center py-8">
				<div className="w-10/12 md:w-8/12">
					<article
						className="blog-post"
						itemScope
						itemType="http://schema.org/Article">
						<header className="py-5">
							<h1 itemProp="headline">{post.frontmatter.title}</h1>
							<p className="py-2 pl-1">Published: {post.frontmatter.date}</p>
							<hr />
						</header>

						<section
							dangerouslySetInnerHTML={{ __html: post.html }}
							itemProp="articleBody"
						/>
						<hr />
					</article>
				</div>
			</div>
			<CtaForm />
			<ProductSection />
		</Layout>
	)
}

export default BlogPostTemplate

export const pageQuery = graphql`
	query BlogPostBySlug(
		$id: String!
		$previousPostId: String
		$nextPostId: String
	) {
		site {
			siteMetadata {
				title
			}
		}
		markdownRemark(id: { eq: $id }) {
			id
			excerpt(pruneLength: 160)
			html
			frontmatter {
				title
				date(fromNow: true)
				description
			}
		}
		previous: markdownRemark(id: { eq: $previousPostId }) {
			fields {
				slug
			}
			frontmatter {
				title
			}
		}
		next: markdownRemark(id: { eq: $nextPostId }) {
			fields {
				slug
			}
			frontmatter {
				title
			}
		}
	}
`
